import { css } from "styled-components"
import { colors } from "../../helpers/colors"
import { BreakPoints, media } from "../../helpers/rwd"
import {
  Content,
  HeaderWrapper,
} from "@gamigo/gatsby-theme-strapi/src/components/header/header-elements"

export const LayoutStyles = css`
  ${HeaderWrapper}.default {
    height: 714px;
    ${media.max(BreakPoints.desktop)} {
      height: auto;
    }
    ${Content} {
      max-width: none;
      width: 69%;
      margin: 61px 0 0;
      padding: 0 0 31px;
      min-height: auto;
      border-bottom: 3px solid ${colors.yellow};
      ${media.max(BreakPoints.desktop)} {
        padding: 0 30px;
      }
      ${media.max(BreakPoints.tablet)} {
        padding: 0;
      }
    }
  }
`

export const ArticleWrapperStyles = css`
  margin: 0 auto;
  border-top: 1px solid ${colors.yellow};
  padding: 63px 0 31px;
  background-color: ${colors.purpleLight};
  ${media.max(BreakPoints.desktop)} {
    margin-top: 0;
  }
`
