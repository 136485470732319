import React from "react"
import { graphql, PageProps } from "gatsby"
import { useTranslation } from "react-i18next"
import ArticleContent from "../../components/article-content"
import Layout from "../../components/layout"
import Header from "../../components/header"
import Footer from "../../components/footer"
import { articleSettings } from "./article-settings"
import { ContentWrapper, MainContent } from "../../ui"
import {
  ArticleQuery,
  IArticle,
  IBanner,
  IFooter,
  IMediaAsset,
  INavigationBar
} from "../../../gatsby-graphql"
import { ArticleWrapper } from "./article-elements"
import { LayoutStyles } from "./article-styles"
import ArticlesSuggestions from "../../sections/articles-suggestions"
import { ILocaleLink } from "../../components/top-bar/common/Locales"

interface PageContext {
  locale: string
  locales: ILocaleLink[]
}

interface ArticleProps extends PageProps {
  data: ArticleQuery
  pageContext: PageContext
}

const Article = ({ data, pageContext }: ArticleProps) => {
  const { t } = useTranslation()

  const path = pageContext.locales.find(item => item.code === pageContext.locale)?.path
  const length = data.article?.slug?.length;
  let pagePath = '/';
  if (path && length) {
    pagePath = path.slice(0, -length-1);
  }

  const header = {
    background: data.article?.background,
    title: data.article?.title
  }
  return (
    <Layout
      topBar={data.topBar}
      locales={pageContext.locales}
      additionalStyles={LayoutStyles}
      seo={data.article?.seo || undefined}
      locale={pageContext.locale}
    >
      <Header
        data={header as IBanner}
        navBarData={data.navBar as INavigationBar}
        className={articleSettings.headerClass}
        locales={pageContext.locales}
        rwdLogo={data.global?.logo as IMediaAsset}
        locale={pageContext.locale}
      />
      <MainContent>
        <ArticleWrapper>
          <ContentWrapper>
            <ArticleContent data={data.article as IArticle} pagePath={pagePath} />
          </ContentWrapper>
        </ArticleWrapper>
        <ArticlesSuggestions
          data={{
            heading: t("Related news")
          }}
          locale={pageContext.locale}
        />
      </MainContent>
      <Footer data={data.footer as IFooter}
              locales={pageContext.locales}
              locale={pageContext.locale}
      />
    </Layout>
  )
}

export default Article

export const pageQuery = graphql`
  query Article($id: String!, $locale: String!) {
    global: strapiGlobal {
      ...FGlobal
    }
    topBar: strapiTopBar(locale: { eq: $locale }) {
      ...FTopBar
    }
    navBar: strapiNavigationBar(locale: { eq: $locale }) {
      ...FNavigationBar
    }
    footer: strapiFooter(locale: { eq: $locale }) {
      ...FFooter
    }
    article: strapiArticle(id: { eq: $id }) {
      ...FArticle
    }
  }
`
