import React from "react"
import { IArticle } from "../../../gatsby-graphql"
import {
  ArticleContentWrapper,
  RichTextWrapper,
  BackToNews,
  TopWrapper,
  Date,
  ArrowBackNews,
  Content,
} from "./article-content-elements"
import ReactMarkdown from "react-markdown"
import rehypeRaw from "rehype-raw";
import { addedClasses } from "../../helpers/markdown/addedClasses"
import { useTranslation } from "react-i18next"

interface ArticleContentProps {
  data: IArticle
  pagePath: string
}

const ArticleContent = ({ data, pagePath }: ArticleContentProps) => {
  const { t } = useTranslation();
  if (!data) {
    return <></>
  }

  return (
    <ArticleContentWrapper>
      <Content to={pagePath}>
        <BackToNews><ArrowBackNews />{t("back to news")}</BackToNews>
      </Content>
      <TopWrapper>
        {data.category ? <>{data.category.name} - </> : <div></div>}
        <Date format="DD.MM.YYYY">{data.published_at}</Date>
      </TopWrapper>
      <RichTextWrapper>
        {/* @ts-ignore */}
        <ReactMarkdown rehypePlugins={[rehypeRaw, addedClasses]}>
          {data.content as string}
        </ReactMarkdown>
      </RichTextWrapper>
    </ArticleContentWrapper>
  )
}

export default ArticleContent
